import { createStore, Store } from 'redux';
import { create } from 'redux-react-hook';
import reducer from './Reducer';
import StepperConfig from '../configuration/StepperConfig';
import { AppState } from '../configuration/AppConfig';



export type AddressInput = {
    street: string,
    postalCode: string,
    province: string,
    city: string,
    country: string
}

export interface StepperPageTransferData extends StepperPageInput {
    type: string,
    applicationId: string
};

export interface BankingStatusUpdate {
    type: string,
    applicationId: string;
    bankingStatus: string;
    bankingInstitution: string;
};

export interface BankingOutcomeData {
    type: string,
    bankingOutcome: string,
    applicationId: string,
    recaptchaResponse: string
}

export type RemainingItem = {
    externalId: string,
    itemName: string,
    description: string,
    status: string
}

export type PartnerRequest = {
    partnerId: string;
    type: string;
}

export type PartnerDetailsRequest = {
    applicationId: string;
    type: string;
}

export type PartnerResponse = {
    companyName: string,
    logoUrl: string,
    logoMobileUrl: string,
    faviconUrl: string,
    primaryColor: string,
    buttonColor: string,
    inverseTitleColor: string,
    formattedAddress: string,
    phone: string,
    email: string,
    website: string,
    whiteLabel: boolean,
    useColourMobileLogo: boolean,
    useEmail: boolean,
    useLandingPage: boolean,
    testimonalText: string,
    testimonalTextFr: string,
    testimonialAuthor: string,
    testimonialAuthorFr: string,
    testimonialImage: string,
    companyTextFirstParagraph: string,
    companyTextFirstParagraphFr: string,
    companyTextSecondParagraph: string,
    companyTextSecondParagraphFr: string,
    companyImage: string,
    specificIndustry: string,
    specificSubIndustry: string,
}

export type InitialPageInput = {
    firstName: string,
    lastName: string,
    email: string,
    businessName: string,
    phoneNumber: string,
    utmSource?: string | string[] | null,
    utmMedium?: string | string[] | null,
    utmCampaign?: string | string[] | null,
    utmTerm?: string | string[] | null,
    utmContent?: string | string[] | null,
    userId?: string | string[] | null,
    companyName?: string | string[] | null, 
    googleClickId?: string | string[] | null,
    googleAnalyticsId?: string | null,
    partnerExternalId?: string | string[] | null,
}

export type StepperPageInput = {
    homeAddress: string;
    businessAddress: string;
    industry: string;
    subIndustry: string;
    dateOfBirth: string;
    approximateAnnualRevenue: number | null;
    approximateAnnualRevenueRange: string;
    requestedAmount: number | null;
    yearsInBusiness: number | null;
    monthsInBusiness: number | null;
    yearsInBusinessRange: string;
    phoneNumber: string;
    legalBusinessName: string;
    consent: boolean;
};

export interface InitialPageTransferData extends InitialPageInput {
    type: string,
    language: string
}

export interface GetTransferData {
    type: string,
    applicationId: string
}

export interface AddressLookupTransferData {
    query: string
}

export type Input = StepperPageInput & InitialPageInput

export type FileInputTransferData = {
    file: any;
    type: string;
    applicationId: string;
    remainingItemExternalId: string;
    recaptchaResponse: string;
}


export interface IState {
    applicationId: string;
    pageIndex: number;
    applicationState: AppState;
    bankAuthorized: boolean;
    bankInProcess: boolean;
    input: Input;
    documents: RemainingItem[];
}

export enum ActionType {
    CHANGE_PAGE = 'change_page',
    CHANGE_APP_STATE = 'change_state',
    CHANGE_INPUT = 'change_input',
    CHANGE_INPUT_ALL = 'change_input_all',
    CHANGE_BANK_AUTH = 'change_bank_auth',
    CHANGE_ALLOWED_TO_PROCEED = 'change_allowed_to_proceed',
    CHANGE_DOCUMENT_DETAILS = 'change_doc_details',
    CHANGE_DOCUMENTS = 'change_docs',
}

export type IAction =
    | {
        type: ActionType,
        value: any,
        propertyName?: string
        index?: number
    };

export type Page = {
    title: string
}

export const { StoreContext, useDispatch, useMappedState } = create<IState, IAction, Store<IState, IAction>>();

export function makeStore(): Store<IState, IAction> {
    return createStore(reducer, INITIAL_STATE);
}

export const INITIAL_STATE: IState = StepperConfig();