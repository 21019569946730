import React, { useState, ChangeEvent } from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import { isMobile } from "react-device-detect";
import { NumberFormatCustom } from "../InputMasks";
import StepperSlider, { StepperMark } from "../StepperSlider";
import { HelperFunctions } from "../../utils/HelperFunctions";

const stepStyles = {
  inputFeedback: {
    position: "absolute" as "absolute",
    // top: 0,
    left: isMobile ? 10 : 40,
    bottom: 0,
  },
};

export default function StepAnnualRevenue() {
  const data = useStepAnnualRevenue();
  const dispatch = useDispatch();

  //   let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  //   let labelStyle = { fontSize: isMobile ? "0.875rem" : "1rem" };
  let size: "sm" | "lg" | undefined = "lg";
  let labelStyle = { fontSize: "1rem" };
  let [typingTimeoutAnnualRevenue, setTypingTimeoutAnnualRevenue] = useState(0);

  const revenueRangeStepTranslation = LocalSettings.translation.applicationPage.annualRevenueRangeStep;

  let marks: StepperMark = {} as StepperMark;
  let endAmount = 0;
  let beginAmount = 0;
  let iterativeAmount = 0;
  let step = 0;

  return (
    <div id="stepAmounts">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {revenueRangeStepTranslation.mainTitle}
      </h2>
      {/* <h4
        style={{
          color: Styles.textColorTitleSecondary,
          textAlign: "center" as "center",
        }}
      >
        What is your approximate annual revenue?
      </h4> */}
      <div className="stepper-form">
        {/* <Row>
          <Col md={12}>
            <div style={{ textAlign: "center" }}>
              <img
                width={300}
                src={`${process.env.PUBLIC_URL}/assets/img/animations_quoting_small.gif`}
              ></img>
            </div>
          </Col>
        </Row> */}
        <Row form>
          <Col md={12}>
            <FormGroup className="stepper-item">
              {/* <Label style={labelStyle}>Approximate Annual Revenue</Label> */}

              {renderSlider()}

              {/* <Input
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (typingTimeoutAnnualRevenue > 0) {
                    clearTimeout(typingTimeoutAnnualRevenue);
                  }
                  data.handleChangeManual(
                    "approximateAnnualRevenue",
                    event.target.value
                  );
                  let timeout = data.approxAnnualRevenueValid ? 0 : 800;
                  setTypingTimeoutAnnualRevenue(
                    setTimeout(() => {
                      data.validateApproxAnnualRevenue(event.target.value);
                    }, timeout)
                  );
                }}
                value={data.approximateAnnualRevenue}
                id="approximateAnnualRevenue"
                invalid={data.approxAnnualRevenueValid}
                placeholder="Your approximate annual revenue"
                name="approximateAnnualRevenue"
                tag={NumberFormatCustom}
                bsSize={size}
              />
              <div
                className="invalid-feedback text-focus-in"
                style={stepStyles.inputFeedback}
              >
                {(() => {
                  if (
                    data.approximateAnnualRevenue &&
                    data.approximateAnnualRevenue < 80000
                  )
                    return "Should not be less than $80,000";
                  else return "Please enter your annual revenue";
                })()}
              </div> */}
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );

  function renderSlider() {
    switch (data.approximateAnnualRevenueRange) {
      case "1":
        endAmount = 250000;
        step = 50000;
        break;
      case "2":
        endAmount = 500000;
        beginAmount = 250000;
        step = 50000;
        break;
      case "3":
        endAmount = 1000000;
        beginAmount = 500000;
        step = 100000;
        break;
      case "4":
        endAmount = 2000000;
        beginAmount = 1000000;
        step = 250000;
        break;
      case "5":
        endAmount = 3000000;
        beginAmount = 2000000;
        step = 250000;
        break;
      case "6":
        endAmount = 5000000;
        beginAmount = 3000000;
        step = 500000;
        break;
    }

    iterativeAmount = beginAmount;
    while (iterativeAmount <= endAmount) {
      let divider = 1000;
      let suffix = "K";
      if (iterativeAmount >= 1000000) {
        divider = 1000000;
        suffix = "M";
      }

      let label = iterativeAmount / divider + suffix;
      marks[iterativeAmount] = {
        style: { top: 10, fontSize: "1rem" },
        label: label,
      };
      iterativeAmount += step;
    }

    return (
      <div>
        <h2
          style={{
            color: Styles.textColorPrimary,
            textAlign: "center" as "center",
          }}
        >
          {data.approximateAnnualRevenue != undefined &&
            data.approximateAnnualRevenue > 0
            ? HelperFunctions.formatCurrency(data.approximateAnnualRevenue, 2, 2, LocalSettings.language) +
            revenueRangeStepTranslation.rangePerYear
            : revenueRangeStepTranslation.rangeSpecific}
        </h2>
        <StepperSlider
          value={data.approximateAnnualRevenue}
          marks={marks}
          step={10000}
          min={beginAmount}
          max={endAmount}
          handleChange={(value?: number) => {
            data.handleChangeManual("approximateAnnualRevenue", value);
          }}
        ></StepperSlider>
      </div>
    );
  }

  function useStepAnnualRevenue(): StepAnnualRevenueState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );

    const handleChangeManual = useCallback(
      (propertyName: string, value: any) => {
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: value,
          propertyName: propertyName,
        });
      },
      []
    );

    const [requestedAmountValid, setRequestedAmountValid] = useState(false);
    const [requestedAmountBlurred, setRequestedAmountBlurred] = useState(false);
    const [approxAnnualRevenueValid, setApproxAnnualRevenue] = useState(false);
    const [
      approxAnnualRevenueBlurred,
      setApproxAnnualRevenueBlurred,
    ] = useState(false);

    function validateRequestedAmount(requestedAmount?: any) {
      let amount = requestedAmount || input.requestedAmount;
      setRequestedAmountValid(
        amount !== undefined && (amount < 5000 || amount > 500000)
      );
    }

    function validateApproxAnnualRevenue(approximateAnnualRevenue?: any) {
      let revenue = approximateAnnualRevenue || input.approximateAnnualRevenue;
      setApproxAnnualRevenue(revenue !== undefined && revenue < 80000);
    }

    return {
      businessName: input.businessName,
      approximateAnnualRevenue: input.approximateAnnualRevenue || undefined,
      approxAnnualRevenueValid: approxAnnualRevenueValid,
      approximateAnnualRevenueRange: input.approximateAnnualRevenueRange,
      validateApproxAnnualRevenue: validateApproxAnnualRevenue,
      approxAnnualRevenueBlurred: approxAnnualRevenueBlurred,
      setApproxAnnualRevenueBlurred: setApproxAnnualRevenueBlurred,
      handleChange,
      handleChangeManual,
    };
  }
}

type StepAnnualRevenueState = {
  businessName: string;
  approximateAnnualRevenue: number | undefined;
  approxAnnualRevenueValid: boolean;
  approximateAnnualRevenueRange: string;
  validateApproxAnnualRevenue: Function;
  approxAnnualRevenueBlurred: boolean;
  setApproxAnnualRevenueBlurred: Function;
  handleChange: Function;
  handleChangeManual: Function;
};
