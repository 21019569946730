import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMappedState, useDispatch, ActionType } from "../store/Store";
import { AppState, LocalSettings, Styles } from "../configuration/AppConfig";
import LoadingScreen from "../components/LoadingScreen";
import { Button, Progress, Spinner } from "reactstrap";
import IFrameLoader from "./IFrameLoader";
import { useParams } from "react-router-dom";
import Api from "../utils/Api";
import Background from "./Background";
import ModalDialog from "./ModalDialog";
import { isMobile } from "react-device-detect";
import "./BankStatementsPage.css";
import ReCAPTCHA from "react-google-recaptcha";
import { HelperFunctions } from "../utils/HelperFunctions";
import TagManager from "react-gtm-module";
import queryString from "query-string";
import useEventListener from "../hooks/UseEventListener";
import useScript from "../hooks/UseScript";
import ReactGA from "react-ga";

export default function BankStatementsPage() {
  const styles = {
    nextButton: {
      margin: "15px",
      minWidth: "100px",
    },
    backButton: {
      margin: "15px",
      minWidth: "100px",
    },
    parentContainer: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      minHeight: 600,
      margin: 10,
    },
    container: {
      width: "100%",
    },
    optionButton: {
      backgroundColor: Styles.textColorPrimary,
      borderColor: Styles.textColorPrimary,
      color: Styles.defaultColor,
      width: 320,
      margin: 5,
    },
    iframe: {
      width: "100%",
      height: isMobile ? 355 : 450,
    },

    tools: {
      position: "absolute",
      right: 50,
      top: 38,
    },
    toolItem: {
      marginLeft: 20,
      marginRight: 20,
    },
    menu: {
      height: 100,
      backgroundColor: Styles.menuColor,
      position: "relative",
    },
    homeLink: {
      top: 38,
      left: 50,
      position: "absolute",
      color: Styles.defaultColor,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    title: {
      maxWidth: 500,
      margin: "auto",
    },
  };

  const dispatch = useDispatch();
  const { applicationState } = useMappedState((state) => state);
  const { applicationId } = useParams<{ applicationId: string }>();

  let recaptchaRef = useRef<ReCAPTCHA>(null);

  if (!applicationId) throw new Error("Application id was not supplied!");

  let queryParams = queryString.parse(window.location.search);
  const userId = queryParams.userId || null;
  const companyName = queryParams.companyName || null;

  const [bankDialogName, setBankDialogName] = useState<string>();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [bankOutcome, setBankOutcome] = useState<BankStatementOutcomes>(
    BankStatementOutcomes.NONE
  );

  // Localization settings
  const bankingTranslation = LocalSettings.translation.bankingPage;

  const companyNameStr = companyName ? `&companyName=${companyName}`: '';

  // For now, always redirecting to documents
  const redirectUrl = encodeURI(`${window.location.origin.toString()}/documents/${applicationId}?language=${LocalSettings.language}${addUserId("&")}${companyNameStr}`);
  const flinksUrl = `${Api.getBankingDataUrl(
    redirectUrl,
    applicationId.toString()
  )}`;

  const setState = useCallback(
    (state: AppState) =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: state }),
    [dispatch]
  );

  const messageHandler = (e: {
    data: { step: string; institution: string };
  }) => {
    const { step, institution } = e.data;
    if (step && institution) {
      Api.updateBankingStatus(
        {
          applicationId: applicationId,
          bankingInstitution: institution,
          bankingStatus: step,
          type: "Self_Update_Banking_Status",
        },
        (result: any) => {
          console.log(result);
        },
        (err: any) => {
          console.error(err);
        }
      );
    }
  };

  useEventListener("message", messageHandler);

  useScript("https://code.tidio.co/two1uyxara5wtxrzklhoegcc5ut7qhvk.js");

  useEffect(() => {
    HelperFunctions.setPageTitle(
      `${bankingTranslation.pageName} - ${LocalSettings.companyName}`
    );

    ReactGA.initialize("UA-15983938-3");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const tagManagerArgs = {
      gtmId: "GTM-KW3ZJC7",
    };
    TagManager.initialize(tagManagerArgs);

    setTimeout(() => {
      setState(AppState.READY);
    }, 1000);
  }, []);

  return renderBankStatementsPage();

  function executeRecaptcha() {
    setState(AppState.LOADING);
    recaptchaRef.current?.execute();
  }

  function addUserId(symbol: "&" | "?") {
    return userId === null ? "" : `${symbol}userId=${userId}`;
  }

  function renderBankStatementsPage() {
    const iframeLoadingStyle = {
      display: iframeLoaded ? "none" : "flex",
      justifyContent: "center",
      height: isMobile ? 385 : 535,
      alignItems: "center",
      flexDirection: "column" as "column",
    };

    if (!applicationId) throw new Error("Application id was not supplied!");

    return (
      <div>
        <div
          style={{
            display: applicationState === AppState.LOADING ? "block" : "none",
          }}
        >
          <LoadingScreen />
        </div>
        <div
          style={{
            display: applicationState === AppState.READY ? "block" : "none",
          }}
        >
          <Background
            useTemplate={true}
            image="stepper"
            useFooter={true}
            useSvg={true}
          >
            <h4
              style={{
                color: Styles.textColorPrimary,
                textAlign: "center" as "center",
                fontSize: isMobile ? "0.9375rem" : "1.25rem",
              }}
            >
              {bankingTranslation.mainTitle}
            </h4>
            <h5
              style={{
                color: Styles.textColorDescription,
                textAlign: "center" as "center",
                fontSize: isMobile ? "0.8125rem" : "1rem",
              }}
            >
              {bankingTranslation.secondaryTitle}
            </h5>
            <div
              className="display-4"
              style={{
                color: Styles.textColorPrimary,
                textAlign: "center",
              }}
            >
              <div style={styles.title} className="mg-description-title"></div>
            </div>
            <div style={iframeLoadingStyle}>
              <div
                style={{
                  color: Styles.textColorPrimary,
                  textAlign: "center",
                }}
                className="iframeWrapper"
              >
                {bankingTranslation.flinksLoadingMessage}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  type="grow"
                  style={{ color: Styles.textColorPrimary }}
                />
                <Spinner
                  type="grow"
                  style={{ color: Styles.textColorPrimary }}
                />
                <Spinner
                  type="grow"
                  style={{ color: Styles.textColorPrimary }}
                />
                <Spinner
                  type="grow"
                  style={{ color: Styles.textColorPrimary }}
                />
              </div>
            </div>
            <div
              style={{
                display: iframeLoaded ? "inherit" : "none",
                textAlign: "center",
              }}
            >
              <IFrameLoader
                id="flinksIframe"
                src={flinksUrl}
                style={styles.iframe}
                frameBorder="0"
                onLoad={() => {
                  setTimeout(() => setIframeLoaded(true), 1500);
                }}
              />
              {renderProtectedCaption()}
            </div>
            {renderBankStatementsModal()}
            {renderBankStatementsButtons()}
            <div style={{ display: "none" }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdpauUUAAAAAHtW_YyUIlSR09BixNeFesguNXUN"
                onChange={(response: string | null) => {
                  if (response != null) {
                    Api.skipBankingStep(
                      {
                        applicationId,
                        type: "Self_Skip_Bank",
                        recaptchaResponse: response,
                        bankingOutcome: bankOutcome,
                      },
                      () => {
                        redirectToNextStep();
                      },
                      () => {
                        alert("An error has occured!");
                      }
                    );
                  } else alert("An error has occured!");
                }}
                onExpired={() => {
                  recaptchaRef.current?.execute();
                }}
              />
            </div>
          </Background>
        </div>
      </div>
    );
  }

  function renderProtectedCaption() {
    let imageDimensionSize, fontSize;
    if (isMobile) {
      imageDimensionSize = 20;
      fontSize = 14;
    } else {
      imageDimensionSize = 40;
      fontSize = 16;
    }
    return (
      <div style={{ textAlign: "center", margin: isMobile ? 0 : 20 }}>
        <div>
          <img
            width={imageDimensionSize}
            height={imageDimensionSize}
            src={`${process.env.PUBLIC_URL}/assets/svg/lock.svg`}
            alt="lock"
          />
          <span style={{ fontSize: fontSize, margin: 0 }} className="lead">
            {bankingTranslation.protectedBy}
          </span>
        </div>
      </div>
    );
  }

  function renderBankStatementsModal() {
    let dialogDetails: any = {};

    switch (bankDialogName) {
      case "confirmstatements":
        dialogDetails.title = bankingTranslation.proceedTitle;
        dialogDetails.open = true;
        dialogDetails.className = "bg-gradient-danger";
        dialogDetails.agreeCaption = bankingTranslation.proceedCancel;
        dialogDetails.disagreeCaption = bankingTranslation.proceedContinue;
        dialogDetails.textStyle = {
          color: "#fff",
          fontSize: 20,
          fontWeight: 400,
        };
        dialogDetails.buttonAgreeStyle = {
          backgroundColor: Styles.textColorPrimary,
          borderColor: Styles.textColorPrimary,
          color: Styles.defaultColor,
        };
        dialogDetails.buttonDisagreeStyle = {
          backgroundColor: Styles.textColorSecondaryAlt,
          borderColor: Styles.textColorSecondaryAlt,
          color: Styles.defaultColor,
          boxShadow: "unset",
        };
        dialogDetails.handleAgree = () => {
          setBankDialogName(BankStatementDialogs.NONE);
        };
        //this.props.handleSetStatements("paper_statements");
        dialogDetails.handleDisagree = () => {
          setBankDialogName(BankStatementDialogs.NONE);
          setBankOutcome(BankStatementOutcomes.SKIP);
          setState(AppState.LOADING);
          executeRecaptcha();
        };
        dialogDetails.handleClose = () =>
          setBankDialogName(BankStatementDialogs.NONE);
        dialogDetails.body = (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ width: 60 }}
              src={`${process.env.PUBLIC_URL}/assets/svg/exclaimation.svg`}
            ></img>
            <div style={{ margin: 40, color: "#fff", fontWeight: 400 }}>
              {bankingTranslation.proceedText}
            </div>
          </div>
        );
        break;
      default:
        return;
    }
    return (
      <div>
        <ModalDialog
          title={dialogDetails.title}
          agreeText={dialogDetails.agreeCaption}
          textStyle={dialogDetails.textStyle}
          disagreeText={dialogDetails.disagreeCaption}
          handleClose={dialogDetails.handleClose}
          handleAgreed={dialogDetails.handleAgree}
          handleDisagreed={dialogDetails.handleDisagree}
          contentClassName={dialogDetails.className}
          open={dialogDetails.open}
          hideCloseButton={false}
          buttonAgreeStyle={dialogDetails.buttonAgreeStyle}
          buttonDisagreeStyle={dialogDetails.buttonDisagreeStyle}
        >
          {dialogDetails.body}
        </ModalDialog>
      </div>
    );
  }

  function renderBankStatementsButtons() {
    if (!applicationId) throw new Error("Application id was not supplied!");

    return (
      <div>
        <div className="buttons-block">
          <Button
            id="proceed_witout_connecting"
            style={styles.optionButton}
            onClick={() => setBankDialogName(BankStatementDialogs.CONFIRM)}
          >
            {bankingTranslation.proceedButton}
          </Button>
          <Button
            style={styles.optionButton}
            onClick={() => {
              setBankOutcome(BankStatementOutcomes.NOT_LISTED);
              executeRecaptcha();
            }}
            id="not_listed"
          >
            {bankingTranslation.notListed}
          </Button>
        </div>
      </div>
    );
  }

  function redirectToNextStep() {
    window.location.href = `/documents/${applicationId}?language=${LocalSettings.language}${companyNameStr}${addUserId("&")}`;
  }
}

type BankStatementsState = {
  applicationId: string;
};

enum BankStatementDialogs {
  CONFIRM = "confirmstatements",
  NONE = "none",
}

enum BankStatementOutcomes {
  SKIP = "Prefer Paper Statements",
  NOT_LISTED = "Bank Not Listed",
  NONE = "None",
}
