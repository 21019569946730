import React, { useState } from "react";
import { useMappedState, useDispatch, ActionType } from "../../store/Store";
import { useCallback } from "react";
import { FormGroup, Form, Label, Input, Col, Row } from "reactstrap";
import { LocalSettings, Styles } from "../../configuration/AppConfig";
import { TextMaskPhone, TextMaskDate } from "../InputMasks";
import { isMobile } from "react-device-detect";
import { HelperFunctions } from "../../utils/HelperFunctions";

export default function StepCustomerPersonalDetails() {
  const dispatch = useDispatch();
  const data = useStepCustomerPersonalDetailsState();

  const customerDetailsStepTranslation = LocalSettings.translation.applicationPage.customerDetailsStep;

  const stepStyles = {
    inputFeedback: {
      position: "absolute" as "absolute",
      // top: 0,
      // left: isMobile ? 10 : 40,
      bottom: 0,
    },
  };

  let [typingTimeOutDateOfBirth, setTypingTimeOutDateOfBirth] = useState(0);
  let [typingTimeOutPhoneNumber, setTypingTimeOutPhoneNumber] = useState(0);

  // let size: "sm" | "lg" | undefined = isMobile ? undefined : "lg";
  // let labelStyle = { fontSize: isMobile ? "0.875rem" : "1rem" };
  let size: "sm" | "lg" | undefined = "lg";
  let labelStyle = { fontSize: "1rem" };
  return (
    <div id="stepCustomerDetails">
      <h2
        style={{
          color: Styles.textColorPrimary,
          textAlign: "center" as "center",
        }}
      >
        {customerDetailsStepTranslation.mainTitle}
      </h2>
      {/* <h4
        style={{
          color: Styles.textColorTitleSecondary,
          textAlign: "center" as "center",
        }}
      >
        Please provide some information about yourself
      </h4>  */}
      <div className="stepper-form">
        <Form>
          {/* <Row>
            <Col md={12}>
              <div style={{ textAlign: "center" }}>
                <img
                  width={300}
                  src={`${process.env.PUBLIC_URL}/assets/img/animations_loading_small.gif`}
                ></img>
              </div>
            </Col>
          </Row> */}
          <Row form>
            <Col md={6}>
              <FormGroup className="stepper-item">
                <Label style={labelStyle}>{customerDetailsStepTranslation.dateOfBirth}</Label>
                <Input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    if (typingTimeOutDateOfBirth > 0) {
                      clearTimeout(typingTimeOutDateOfBirth);
                    }
                    data.handleChange(event);
                    let timeout = !data.dateOfBirthValid ? 0 : 2000;
                    setTypingTimeOutDateOfBirth(
                      setTimeout(() => {
                        data.validateDateOfBirth(value);
                      }, timeout)
                    );
                  }}
                  tag={TextMaskDate}
                  value={data.dateOfBirth}
                  id="dateOfBirth"
                  placeholder={customerDetailsStepTranslation.dateOfBirthPlaceholder}
                  name="dateOfBirth"
                  autoComplete="bday"
                  type="text"
                  bsSize={size}
                  invalid={!data.dateOfBirthValid}
                />
                <div
                  className="invalid-feedback text-focus-in"
                  style={stepStyles.inputFeedback}
                >
                  {(() => {
                    if (data.dateOfBirth != null && data.dateOfBirth != "") {
                      if (HelperFunctions.dateIsValid(data.dateOfBirth)) {
                        let age = HelperFunctions.getAgeFromDateOfBirth(
                          data.dateOfBirth
                        );
                        if (age < 18)
                          return customerDetailsStepTranslation.dobAgeInvalid;
                        else if (age > 120) return customerDetailsStepTranslation.dobDateInvalid;
                      } else {
                        return customerDetailsStepTranslation.dobFormatInvalid;
                      }
                    } else {
                      return customerDetailsStepTranslation.dobDateMissing;
                    }
                  })()}
                </div>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="stepper-item">
                <Label style={labelStyle}>{customerDetailsStepTranslation.mobilePhone}</Label>
                <Input
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let value = event.target.value;
                    if (typingTimeOutPhoneNumber > 0) {
                      clearTimeout(typingTimeOutPhoneNumber);
                    }
                    data.handleChange(event);
                    let timeout = !data.phoneNumberValid ? 0 : 2000;
                    setTypingTimeOutPhoneNumber(
                      setTimeout(() => {
                        data.validatePhoneNumber(value);
                      }, timeout)
                    );
                  }}
                  tag={TextMaskPhone}
                  value={data.phoneNumber}
                  id="phoneNumber"
                  placeholder={customerDetailsStepTranslation.mobilePhonePlaceholder}
                  name="phoneNumber"
                  type="text"
                  autoComplete="tel"
                  bsSize={size}
                  invalid={!data.phoneNumberValid}
                />
                <div
                  className="invalid-feedback text-focus-in"
                  style={stepStyles.inputFeedback}
                >
                  {(() => {
                    if (data.phoneNumber != "") {
                      if (!HelperFunctions.phoneIsValid(data.phoneNumber)) {
                        return customerDetailsStepTranslation.mobilePhoneInvalid;
                      }
                    } else {
                      return customerDetailsStepTranslation.mobilePhoneMissing;
                    }
                  })()}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  function useStepCustomerPersonalDetailsState(): StepCustomerPersonalDetailsState {
    const { input } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );

    const [dateOfBirthValid, setDateOfBirthValid] = useState(true);
    const [dateOfBirthBlurred, setDateOfBirthBlurred] = useState(false);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [phoneNumberBlurred, setPhoneNumberBlurred] = useState(false);

    function validateDateOfBirth(dateOfBirthStr: string) {
      setDateOfBirthValid(
        dateOfBirthStr != "" &&
          HelperFunctions.dateIsValid(dateOfBirthStr) &&
          HelperFunctions.getAgeFromDateOfBirth(dateOfBirthStr) >= 18 &&
          HelperFunctions.getAgeFromDateOfBirth(dateOfBirthStr) <= 99
      );
    }

    function validatePhoneNumber(phoneNumberStr: string) {
      setPhoneNumberValid(
        phoneNumberStr != "" && HelperFunctions.phoneIsValid(phoneNumberStr)
      );
    }

    return {
      dateOfBirth: input.dateOfBirth,
      dateOfBirthValid: dateOfBirthValid,
      dateOfBirthBlurred: dateOfBirthBlurred,
      setDateOfBirthBlurred: setDateOfBirthBlurred,
      validateDateOfBirth: validateDateOfBirth,
      setDateOfBirthValid: setDateOfBirthValid,
      phoneNumber: input.phoneNumber,
      phoneNumberValid: phoneNumberValid,
      phoneNumberBlurred: phoneNumberBlurred,
      setPhoneNumberBlurred: setPhoneNumberBlurred,
      validatePhoneNumber: validatePhoneNumber,
      setPhoneNumberValid: setPhoneNumberValid,
      handleChange,
    };
  }
}

type StepCustomerPersonalDetailsState = {
  dateOfBirth: string;
  dateOfBirthValid: boolean;
  dateOfBirthBlurred: boolean;
  setDateOfBirthBlurred: Function;
  validateDateOfBirth: Function;
  setDateOfBirthValid: Function;
  phoneNumber: string;
  phoneNumberValid: boolean;
  phoneNumberBlurred: boolean;
  setPhoneNumberBlurred: Function;
  validatePhoneNumber: Function;
  setPhoneNumberValid: Function;
  handleChange: Function;
};
