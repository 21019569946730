import axios from "axios";
import { LocalSettings } from "../configuration/AppConfig";
import { StepperPageTransferData, InitialPageTransferData, FileInputTransferData, GetTransferData, AddressLookupTransferData, BankingOutcomeData, PartnerRequest, PartnerResponse, BankingStatusUpdate, PartnerDetailsRequest } from "../store/Store";
import { HelperFunctions } from "./HelperFunctions";

let webApiAddress: string;
switch (process.env.NODE_ENV) {
  case "development":
    webApiAddress = "http://localhost:52932";
    break;
  case "production":
    webApiAddress = "https://mg-online-application-prod-api.azurewebsites.net";
    // webApiAddress = "https://mg-online-application-dev-api.azurewebsites.net";
    break;
  default:
    throw new Error("Unknown environment!");
}

const Api = {
  lookupAddress: async function (input: AddressLookupTransferData, successCallback: Function, errorCallback: Function){
    const url = webApiAddress + `/api/application/get`;
  },
  getApplication : async function (input: GetTransferData, successCallback : Function, errorCallback: Function){
    const url = webApiAddress + `/api/application/get`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  },
  createApplication : async function (input : InitialPageTransferData, successCallback : Function, errorCallback: Function){
    const url = webApiAddress + `/api/application/create`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  },
  updateApplication : async function (input: StepperPageTransferData, successCallback: Function, errorCallback: Function){
    let url = webApiAddress + `/api/application/update`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  },
  updateBankingStatus : async function (input: BankingStatusUpdate, successCallback : Function, errorCallback: Function){
    let url = webApiAddress + `/api/application/update`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  },
  skipBankingStep : async function (input: BankingOutcomeData, successCallback: Function, errorCallback: Function){
    let url = webApiAddress + `/api/application/update`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  }, 
  uploadFile: async function (input: FormData, successCallback: Function, errorCallback : Function, progressCallback: Function){
    const url = webApiAddress + `/api/application/upload`;
    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      },
      onUploadProgress: (e: any) => { progressCallback(e) } 
    }
    await makePostRequest(input, url, config, successCallback, errorCallback);
  },
  getUploadUrl: function () : string {
    return webApiAddress + '/api/application/upload';
  },
  getBankingDataUrl: function (redirectUrl: string, applicationId: string) : string {
    let flinksUrl;
    let language = LocalSettings.language;

    let redirectUrlWithoutAmpersand = HelperFunctions.replaceAmpersands(redirectUrl);
    let encodedUrl = encodeURI(redirectUrlWithoutAmpersand);

    switch(process.env.NODE_ENV) {
        case 'development':
            flinksUrl = `https://merchant-growth9260.connect.flinks.dev/v2/?demo=true&language=${language}&tag=${encodedUrl}&theme=light&enhancedMFA=true&innerRedirect=false&institutionFilterEnable=true&desktopLayout=true&headerImgEnable=true&headerEnable=false&backgroundColor=ffffff&redirectUrl=`;
        break;
        case 'production':
            //flinksUrl = `https://merchant-growth9260.connect.flinks.dev/v2/?demo=true&language=${language}&tag=${encodedUrl}&theme=light&enhancedMFA=true&innerRedirect=false&institutionFilterEnable=true&desktopLayout=true&headerImgEnable=true&headerEnable=false&backgroundColor=ffffff&redirectUrl=`;
            flinksUrl = `https://merchantadvance-iframe.private.fin.ag/v2/?language=${language}&tag=${encodedUrl}&theme=light&enhancedMFA=true&innerRedirect=false&institutionFilterEnable=true&desktopLayout=true&headerImgEnable=true&headerEnable=false&innerRedirect=false&daysOfTransactions=Days365&backgroundColor=ffffff&redirectUrl=`;
        break;
        default:
            throw new Error(`Unsupported configuration - ${process.env.NODE_ENV}`);
    }
    return `${flinksUrl}${webApiAddress}/api/application/callback?applicationId=${applicationId}`; 
  },
  getHubUrl: function () : string {
    return `${webApiAddress}/apphub`;
  },
  getPartnerData: async function (input : PartnerRequest, successCallback: (result: any) => void, errorCallback: (error: any) => void) {
    let url = webApiAddress + `/api/application/get`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  },
  getPartnerDetails: async function (input: PartnerDetailsRequest, successCallback: (result: any) => void, errorCallback: (error: any) => void) {
    let url = webApiAddress + `/api/application/get`;
    await makePostRequest(input, url, null, successCallback, errorCallback);
  } 
}

export default Api;

async function makePostRequest(
  payload: any,
  url: string,
  config: any,
  successCallback: Function,
  errorCallback: Function
) {
  try {
    const response = await axios.post(url, payload, config);
    successCallback(response);
  } catch (errorResponse) {
    errorCallback(errorResponse);
  }
}

async function makeGetRequest(
  url: string,
  successCallback: Function,
  errorCallback: Function,
  useCredentials: boolean
) {
  try {
    const response = await axios.get(url, { withCredentials: useCredentials });
    successCallback(response);
  } catch (errorResponse) {
    errorCallback(errorResponse);
  }
}

