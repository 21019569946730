import React from "react";
import { LocalSettings } from "../configuration/AppConfig";
import { isMobile } from "react-device-detect";

function TermsAndConditions(props: any) {
  const { textStyle } = props;
  const styles = {
    hyperlink: {
      margin: 30,
      textDecoration: "underline",
    },
    container: {
      textAlign: "center" as "center",
      margin: 10,
    },
  };

  let text: string;
  let policy: string;
  let policyUrl: string;
  switch (LocalSettings.language) {
    case "en":
      text = `By agreeing to the following Terms & Conditions, the Merchant and its
      owner(s) certify that all information and documents submitted in
      connection with this Application are true, correct and complete.
      Additionally, the owner(s) authorize ${LocalSettings.companyNameOriginal} Ltd. or any of its
      agents, partners, and affiliates to obtain and use business and
      non-business consumer credit reports from credit reporting agencies and
      any other information regarding the Merchant and its owner(s) from third
      parties, both at the time of the initial funding application and at any
      time after the Merchant has received funding as long as the Merchant
      remains a client of ${LocalSettings.companyNameOriginal} Ltd. At all times, ${LocalSettings.companyNameOriginal} Ltd. 
      will comply with the personal information collection, protection,
      use, sharing, and retention practices set out in the Privacy Policy, which
      can be reviewed here:`;
      policy = "Merchant Growth privacy policy";
      policyUrl = "https://www.merchantgrowth.com/privacy-policy";
      break;
    case "fr":
      text = `En acceptant les conditions générales suivantes, le commerçant et son (ses) propriétaire (s) certifient que toutes 
      les informations et tous les documents soumis dans le cadre de cette demande sont véridiques, corrects et complets. 
      De plus, le propriétaire (s) autorise (nt) ${LocalSettings.companyNameOriginal} Ltd. ou l'un de ses agents, partenaires et affiliés à obtenir et à utiliser des rapports 
      de solvabilité d'entreprise et personnels auprès d'agences d'évaluation du crédit et toute autre information provenant de tiers concernant 
      le commerçant et son (ses) propriétaire (s), à partir du moment de la demande de financement initiale et à tout moment après que le commerçant 
      a reçu des fonds et tant que le commerçant reste un client de ${LocalSettings.companyNameOriginal} Ltd. À tout moment, ${LocalSettings.companyNameOriginal} Ltd. se conformera aux pratiques de collecte, 
      de protection, d'utilisation, de partage et de conservation des informations énoncées dans la politique de confidentialité, qui peut être consultée ici:`;
      // text = `En acceptant les conditions générales suivantes, le ${LocalSettings.companyNameOriginal} Ltd. et son
      // le (s) propriétaire (s) certifie (nt) que toutes les informations et tous les documents soumis
      // connexion avec cette application sont vraies, correctes et complètes.
      // En outre, le ou les propriétaires autorisent ${LocalSettings.companyNameOriginal} Ltd. ou l'un de ses
      // agents, partenaires et affiliés pour obtenir et utiliser des affaires et
      // les rapports de solvabilité des consommateurs non commerciaux des agences d'évaluation du crédit et
      // toute autre information concernant ${LocalSettings.companyNameOriginal} Ltd. et son (ses) propriétaire (s) de tiers
      // parties, à la fois au moment de la demande de financement initiale et à tout moment
      // heure après que ${LocalSettings.companyNameOriginal} Ltd. ait reçu un financement tant que le marchand
      // reste un client de ${LocalSettings.companyNameOriginal} Ltd. À tout moment, ${LocalSettings.companyNameOriginal} Ltd. se conformera à la collecte des informations personnelles, à la protection,
      // les pratiques d'utilisation, de partage et de conservation énoncées dans la politique de confidentialité, qui
      // peut être examiné ici:`;
      policy = "Politique de confidentialité de Merchant Growth";
      policyUrl = "https://www.merchantgrowth.com/fr/privacy-policy";
      break;
  }
  return (
    <div
      style={{
        overflowY: isMobile ? "scroll" : "hidden",
        maxHeight: isMobile ? "425px" : "auto",
      }}
    >
      <p style={textStyle}>
        {text}
        <p style={styles.container}>
          <a
            style={{ ...styles.hyperlink, ...textStyle }}
            target="_blank"
            href={policyUrl}
          >
            {policy}
          </a>
        </p>
      </p>
    </div>
  );
}

export default TermsAndConditions;
